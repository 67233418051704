import React, { useState, useEffect } from 'react';
import { web } from '../../helpers/api';
import { ENDPOINT, entitiesLabels, PATH } from '../../helpers/Constants';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import CustomTreeView from '../CustomComponent/CustomTreeViews';
export default function Questionario(props) {
    const navigate = useNavigate();
    const [isViewaddQuestion, setIsViewaddQuestion] = useState(true);
    const [valoreChek, setValoreChek] = useState(false);
    const [valoreChekAssociazione, setValoreChekAssociazione] = useState(false);
    const [isViewTypeQuestion, setIsViewTypeQuestion] = useState(false);
    const [isViewTipoConfronto, setIsViewTipoConfronto] = useState(false);
    const [isDisabledAction, setisDisabledAction] = useState(false);
    const [isDisabledValoreConfronto, setIsDisabledValoreConfronto] = useState(false);
    const [idProgressive, setProgressive] = useState(1);
    const [typeConfronto, setTypeConfronto] = useState(0);
    const [valoreConfronto, setValoreConfronto] = useState('');
    const [typeQuestion, setTypeQuestion] = useState(0);
    const [domanda, setDomanda] = useState('');
    const [titoloQuestionario, setTitoloQuestionario] = useState('');
    const [rispostaSingola, setRispostaSingola] = useState('');
    const [idDomandaDaCollegare, setIdDomandaDaCollegare] = useState(null);
    const [idDomandaPadre, setIdDomandaPadre] = useState(null);
    let [listaDomande, setlistaDomande] = useState([]);
    let [listaRispostaSingola, setListaRispostaSingola] = useState([]);
    const APERTA = 1;
    const NUMERICA = 2;
    const RISPSINGOLA = 3;
    const DATA = 4;
    const VF = 5;
    useEffect(() => {
        fetchProfile();
    }, []);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOQUESTIONARIO, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setlistaDomande(response.data.dati);
                        setTitoloQuestionario(response.data.dati[1].titolo)
                    }
                }).catch((error) => {

                });
        }
    };

    const handleChange = (event) => {
        setTypeQuestion(parseInt(event.target.value));
    };
    const handleChangeTitle = (event) => {
        setTitoloQuestionario(event.target.value);
    };
    const handleChangeConfronto = (event) => {
        setTypeConfronto(parseInt(event.target.value));
    };
    const handleChangeDomanda = (event) => {
        setDomanda(event);
    };
    const handleValoreDiConfronto = (event) => {
        if (isViewTipoConfronto) {
            setValoreConfronto(event);
        }
    };
    const handleChangeRisposta = (event) => {

        setRispostaSingola(event);
    };
    const addDomanda = async (domanda) => {

        var questionatio = {
            idQuestionario: window.location.pathname.split('/').pop() === 'modello' ? 0 : parseInt(window.location.pathname.split('/').pop()),
            titolo: titoloQuestionario,
            id: 0,
            domanda: domanda.domanda,
            tipodomanda: domanda.tipodomanda,
            listaRisposte: domanda.listaRisposte,
            idDomandaCollegata: domanda.idDomandaCollegata,
            typeConfronto: domanda.typeConfronto,
            valoreConfronto: domanda.valoreConfronto,
            idPadre: domanda.idDomandaCollegata ? null: idDomandaPadre,
            lvl: 0
        }
        if (domanda != null) {
            await web.post(ENDPOINT.SALVADOMANDA, questionatio)
                .then((response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        if (window.location.pathname.split('/').pop() === 'modello') {
                                               
                            navigate(PATH.DETTAGLIOMODELLO.toString().slice(0, -4) + "/" + response.data.dati, { replace: true });
                            fetchProfile()
                        } else {
                            fetchProfile()
                        }
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {

                });
        }
    }
    function addRispostaSingola(rispsota) {
        setListaRispostaSingola([...listaRispostaSingola, rispsota]);
    }
    const deleteQuestion = async (id) => {
        if (id) {
            await web.delete(ENDPOINT.ELIMINADOMANDA, id)
                .then((response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        fetchProfile()
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {

                });
        }
    }
    function saveValoreDiConfronto() {
        setIsDisabledValoreConfronto(!isDisabledValoreConfronto);
        setIsViewTypeQuestion(!isViewTypeQuestion);
    }
    function addDomandaCollegata(id) {
        setIdDomandaDaCollegare(id);
        setIsViewTipoConfronto(!isViewTipoConfronto);
        setIsViewaddQuestion(false);
        setisDisabledAction(!isDisabledAction)
    }
    function aggiungiDomandaIdPadre(idPadre) {
        setIsViewaddQuestion(false);
        setIsViewTypeQuestion(true)
        setIdDomandaPadre(idPadre);
    }
    function changeCheck() {
        if (isViewTipoConfronto) {
            setValoreChekAssociazione(!valoreChekAssociazione);
            handleValoreDiConfronto(!valoreChekAssociazione ? 'Vero' : 'Falso');
        } else {
            setValoreChek(!valoreChek);
        }
    }
    function salvaDomanda() {
        setProgressive(idProgressive + 1);
        addDomanda({
            id: idProgressive,
            domanda: domanda,
            tipodomanda: typeQuestion,
            listaRisposte: listaRispostaSingola.length > 0 ? listaRispostaSingola : [],
            idDomandaCollegata: idDomandaDaCollegare,
            typeConfronto: typeConfronto,
            valoreConfronto: typeConfronto === 3 && valoreConfronto === '' ? 'Falso' : valoreConfronto  //ATTENZIONE QUESTA CONDIZIONE INDICA CHE SE IL typeConfronto == 3 Significa che siamo nel caso di un inserimento Check  Quindi se il valore è '' significa che è false 
        });
        setTypeQuestion(0);
        setIsViewaddQuestion(true);
        setIsViewTypeQuestion(false);
        setListaRispostaSingola([])
        setIsViewTipoConfronto(false);
        setTypeConfronto(0)
        setisDisabledAction(false);
        setIsDisabledValoreConfronto(false);
        setIdDomandaDaCollegare(null);
        setValoreChekAssociazione(false);
        setValoreConfronto('');

    }
    function handleCloseModalNewQuestion() {
        setTypeQuestion(0);
        setIsViewaddQuestion(true);
        setIsViewTypeQuestion(false);
        setListaRispostaSingola([])
        setIsViewTipoConfronto(false);
        setTypeConfronto(0)
        setisDisabledAction(false);
        setIsDisabledValoreConfronto(false);
        setIdDomandaDaCollegare(null);
        setValoreChekAssociazione(false);
        setValoreConfronto('');
    }
    function returnElement(isDisabled, typo, listaRisposte, viewAddResponce) {
        return (
            //|IDENTIFICA IL TIPO DI RISPOSTA  
            typo === APERTA ? //APERTA
                <><Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Control placeholder="" disabled={isDisabled || isDisabledValoreConfronto} className={(isDisabled || isDisabledValoreConfronto) ? 'w-25 b-input-disable-custom' : 'w-25'} />
                    </Form.Group>
                </Row>
                </>
                :
                typo === NUMERICA ?//NUMERO
                    <Form.Control
                        onChange={event => handleValoreDiConfronto(event.target.value)}
                        disabled={isDisabled || isDisabledValoreConfronto}
                        type="number"
                        id="number"
                        aria-describedby=""
                        className={(isDisabled || isDisabledValoreConfronto) ? 'b-input-disable-custom' : ''}
                    /> :
                    typo === RISPSINGOLA ?//RISPOSTA SINGOLA
                        <>
                            <Form.Select onChange={(event) => handleValoreDiConfronto(event.target.value)} aria-label="Default select example" className='m-b-10 ' >
                                <option>Seleziona risposte</option>
                                {
                                    listaRisposte && listaRisposte.length > 0 ? (
                                        listaRisposte.map((item) => (
                                            <option>{item}</option>
                                        )))
                                        :
                                        listaRispostaSingola && listaRispostaSingola.length > 0 ? (
                                            listaRispostaSingola.map((item) => (
                                                <option>{item}</option>
                                            ))) : <></>
                                }
                            </Form.Select>
                            {viewAddResponce ?
                                <InputGroup className="mb-3 ">
                                    <Form.Control
                                        value={rispostaSingola}
                                        aria-describedby="basic-addon1"
                                        placeholder="Inserisci risposta"
                                        onChange={event => handleChangeRisposta(event.target.value)}
                                    />
                                    <Button variant="outline-secondary" id="button-addon1" onClick={() => { addRispostaSingola(rispostaSingola); setRispostaSingola('') }}>+</Button>
                                </InputGroup> : <></>}

                        </>
                        : typo === DATA ? //DATA
                            <>
                                <Form.Control className={(isDisabled || isDisabledValoreConfronto) ? ' b-input-disable-custom' : ''}
                                    onChange={event => handleValoreDiConfronto(event.target.value)}
                                    disabled={isDisabled || isDisabledValoreConfronto}
                                    type="date"
                                    id="number"
                                    aria-describedby=""
                                />
                            </>
                            :
                            typo === VF ?//CHECK
                                <>{
                                    isDisabled ?
                                        <Form.Check
                                            onClick={() => changeCheck()}
                                            type="switch"
                                            id="custom-switch"
                                            label={valoreChek ? 'Vero' : 'Falso'}
                                        />
                                        :
                                        <Form.Check
                                            onClick={() => changeCheck()}
                                            type="switch"
                                            id="custom-switch"
                                            label={valoreChekAssociazione ? 'Vero' : 'Falso'}
                                        />
                                }</> : <></>
        )
    }
    return (
        <Form>
            <Row className="mb-1">
                <Form.Group as={Col} >
                    <Form.Label>Titolo Questionario</Form.Label>
                    <Form.Control onChange={(el) => handleChangeTitle(el)} type="titolo questionario" placeholder="Inserisci  titolo questionario" value={titoloQuestionario} />
                </Form.Group>
            </Row>
            <CustomTreeView items={listaDomande} titolo={titoloQuestionario} deleteQuestion={(id) => deleteQuestion(id)} addDomandaCollegata={(id) => addDomandaCollegata(id)} aggiungiDomandaIdPadre={(id) => aggiungiDomandaIdPadre(id)}></CustomTreeView>

            {isViewaddQuestion && listaDomande.length === 0 ? <Button variant="primary" className='p-t-10' onClick={() => { setIsViewaddQuestion(false); setIsViewTypeQuestion(true) }} >Aggiungi Domanda</Button> : <></>}
            {isViewTipoConfronto ?
                <>
                    <Modal show={true} onHide={() => handleCloseModalNewQuestion()} className='custom-m-modal '>
                        <Modal.Header closeButton>
                            <Modal.Title>Collega Domanda</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="mb-6">
                                <Form.Group as={Col} >
                                    <Form.Select aria-label="Default select example" className='m-b-10' onChange={(event) => handleChangeConfronto(event)} disabled={isDisabledValoreConfronto}>
                                        <option>Seleziona confronto</option>
                                        {(listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda !== 3 && listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda !== 5) ? <option value="1">Maggiore</option> : ''}
                                        {(listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda !== 3 && listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda !== 5) ? <option value="2">Minore</option> : ''}
                                        <option value="3">Uguale</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mb-6">
                                <Form.Group as={Col} >
                                    <Form.Label>Valore del confronto</Form.Label>
                                    {returnElement(false, listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda, listaDomande.find(x => x.id === idDomandaDaCollegare).listaRisposte, false)
                                    }
                                </Form.Group>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleCloseModalNewQuestion()}  >
                                Close
                            </Button>
                            <Button className="p-t-10" variant="primary" id="button-addon1" disabled={((listaDomande.find(x => x.id === idDomandaDaCollegare).tipodomanda !== 1 && valoreConfronto === '') || typeConfronto === 0) ? true : false} onClick={() => saveValoreDiConfronto()} >Salva confronto e aggiungi domanda</Button>

                        </Modal.Footer>
                    </Modal>
                </>
                : <></>
            }
            {isViewTypeQuestion ?
                <>
                    <Modal show={true} onHide={() => handleCloseModalNewQuestion()} className='custom-m-modal '>
                        <Modal.Header closeButton>
                            <Modal.Title>Nuova Domanda</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="mb-6">
                                <Form.Group as={Col} >
                                    <Form.Control as="textarea" rows={6} placeholder="Inserisci  Domanda" onChange={event => handleChangeDomanda(event.target.value)} name="domanda" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-6">
                                <Form.Group as={Col} >
                                    <Form.Label htmlFor="numero" >Tipo Domanda</Form.Label>
                                    <Form.Select aria-label="Default select example" className='m-b-10' onChange={(event) => handleChange(event)}>
                                        <option>Seleziona tipo domanda</option>
                                        <option value="1">Risposta Aperta</option>
                                        <option value="2">Numerico</option>
                                        <option value="3">Risposta singola</option>
                                        <option value="4">Data</option>
                                        <option value="5">Vero Falso</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            {typeQuestion === RISPSINGOLA ?
                                <Row>
                                    <Form.Group as={Col} >
                                        <Form.Select disabled={listaRispostaSingola.length > 0 ? false : true} onChange={(event) => handleValoreDiConfronto(event.target.value)} aria-label="Default select example" className='m-b-10 ' >
                                            <option>{listaRispostaSingola.length > 0 ? "Visualizza le risposte" : "Aggiungi risposte per visualizzarle"}</option>
                                            {
                                                listaRispostaSingola && listaRispostaSingola.length > 0 ? (
                                                    listaRispostaSingola.map((item) => (
                                                        <option>{item}</option>
                                                    ))) : <></>
                                            }
                                        </Form.Select>
                                        <InputGroup className="mb-3 ">
                                            <Form.Control
                                                value={rispostaSingola}
                                                aria-describedby="basic-addon1"
                                                placeholder="Inserisci risposta"
                                                onChange={event => handleChangeRisposta(event.target.value)}
                                            />
                                            <Button variant="outline-secondary" id="button-addon1" onClick={() => { addRispostaSingola(rispostaSingola); setRispostaSingola('') }}>+</Button>
                                        </InputGroup>
                                    </Form.Group>

                                </Row> : <></>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleCloseModalNewQuestion()}  >
                                Close
                            </Button>
                            {
                                (typeQuestion === APERTA || typeQuestion === NUMERICA || typeQuestion === DATA || typeQuestion === VF || (typeQuestion === 3 && listaRispostaSingola.length > RISPSINGOLA)) ?
                                    <Button className="p-t-10" variant="primary" onClick={() => salvaDomanda()} >Salva Domanda</Button> : <></>
                            }
                        </Modal.Footer>
                    </Modal>
                </> : <></>
            }


            <NotificationContainer></NotificationContainer>
        </Form>
    );
}