import { Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from '../CustomComponent/Pagination';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import { NotificationContainer, NotificationManager } from 'react-notifications';

function AssociazionePaziente() {
    const [elements, setPatients] = useState([]); // All patients
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [search, setSearch] = useState('')
    const [filteredElements, setFilteredElements] = useState([]);
    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        fetchPatients();
    }, []);

    const fetchPatients = async () => {
        await web.post(ENDPOINT.ELENCOPAZIENTIASSOCIATI, 1)
            .then((response) => {
                if (response.status === 200) {
                    setPatients(response.data.dati.pazienteWithFlagDTO);
                }
            }).catch((error) => { })

    };

    const indexOfLastElement = currentPage * elementsPerPage;
    const indexOfFirstElement = indexOfLastElement - elementsPerPage;
    let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleChange = (el) => {
        const inputValue = el.target.value;
        setSearch({
            search: inputValue
        });
        if (inputValue) {
            if (search && search?.search.length >= 1) {
                setIsFilter(true);
                let filteredData = elements.filter(x => String(x.nome).includes(inputValue)
                    || String(x.cognome).includes(inputValue)
                    || String(x.codicePaziente).includes(inputValue)
                    || String(x.phoneNumber).includes(inputValue)
                    || String(x.fiscalCode).includes(inputValue)
                    || String(x.email).includes(inputValue));
                if (filteredData.length > 0) {
                    setFilteredElements(filteredData);
                } else {
                    setFilteredElements(filteredData);
                }
                setCurrentPage(1)
            } else {
                setFilteredElements(elements);
            }
        } else {
            setFilteredElements(elements);
        }
    };

    const handleSelectPatient = (idPaziente) => {
        setPatients(prevState => {
            const updatedElements = prevState.map(pa =>
                pa.idPaziente === idPaziente
                    ? { ...pa, questionarioAssegnato: !pa.questionarioAssegnato }
                    : pa
            );
            if (isFilter) {
                setFilteredElements(updatedElements);
            }

            return updatedElements;
        });
    };

    const handleSelectAll = () => {
        const allSelected = elements.every(pa => pa.questionarioAssegnato);
        const updatedElements = elements.map(pa => ({
            ...pa,
            questionarioAssegnato: !allSelected
        }));

        setPatients(updatedElements);
        if (isFilter) {
            setFilteredElements(updatedElements);
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await web.post(ENDPOINT.ASSOCIAPAZIENTIQUESTIONARIO, { idQuest: 1, titolo: '', pazienteWithFlagDTO: elements });
            if (response.status === 200) {
                NotificationManager.success(message.SuccessAssociazione, entitiesLabels.SUCCESS, 3000);
                fetchPatients();
            }
        } catch (error) {
            NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
        }
    };

    return (
        <>
            <Form.Group className="d-flex mb-3">
                <Form.Control className="me-3" onChange={handleChange} name="search" placeholder="Ricerca Paziente" />
            </Form.Group>
            <div className="table-wrapper">
                <table className="table custom">
                    <thead>
                        <tr>
                            <th scope="col">
                                <Form.Check
                                    type="checkbox"
                                    checked={currentElements.filter(x => x.questionarioAssegnato).length === currentElements.length}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col">Cognome</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {
                            currentElements?.map((pa) => (
                                <tr key={pa.id}>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={pa.questionarioAssegnato}
                                            onChange={() => handleSelectPatient(pa.idPaziente)}
                                        />
                                    </td>
                                    <td>{pa.nome}</td>
                                    <td>{pa.cognome}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <Pagination
                elementPerPage={elementsPerPage}
                totalElements={isFilter ? filteredElements?.length : elements?.length}
                paginate={paginate}
                currentPage={currentPage}
            />
            <Button onClick={handleSubmit}>Associa</Button>
            <NotificationContainer />
        </>
    );
}

export default AssociazionePaziente;
