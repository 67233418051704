import { PATH , role} from './helpers/Constants';



export function isPreLogin() {
    if (window.location.pathname === PATH.SPLASH
        || window.location.pathname.toLowerCase() === PATH.LOGIN
        || window.location.pathname.toLowerCase() === PATH.RECOVERY
        || window.location.pathname.toLowerCase() === PATH.REGISTRAZIONEMEDICO
    ) {
        return false
    } else {
        return true
    }
}

export function ispostLogin() {
    if (window.location.pathname !== PATH.SPLASH
        || window.location.pathname.toLowerCase() !== PATH.LOGIN
        || window.location.pathname.toLowerCase() !== PATH.RECOVERY
    ) {
        return true
    } else {
        return false
    }
}

export function checkIsUserLogin() {
    if (window.location.pathname !== PATH.SPLASH.toString()
        && window.location.pathname.toLowerCase() !== PATH.LOGIN
        && window.location.pathname.toLowerCase() !== PATH.RECOVERY
    ) {
        if (localStorage.getItem("accessToken") === null) {
            window.location.href = "/";
            return false;
        }else{
            return true;
        }
    }
}

export function IsMedico() {
    return JSON.parse(localStorage.getItem("role")).idRole === role.MEDICO ? true : false
}
export function isOperatore() {
    return JSON.parse(localStorage.getItem("role")).idRole === role.OPERATORE ? true : false
}
export function isAdmin() {
    return JSON.parse(localStorage.getItem("role")).idRole === role.ADMIN ? true : false
}
export function isSystem() {
    return JSON.parse(localStorage.getItem("role")).idRole=== role.SISTEMA ? true : false
}