import React, { useState, useEffect } from 'react';
import { web } from '../../helpers/api';
import { ENDPOINT } from '../../helpers/Constants';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

function Feedback() {
    const [domande, setDomande] = useState([]);  // Initialize as an empty array
    const [risposte, setRisposte] = useState([]);  // Initialize as an empty array

    useEffect(() => {
        // Funzione per ottenere i dati dell'aderenza quando l'anno cambia
        fetchAderenza();
        fetchRisposte();
    }, []);  // Fetch data on component mount

    const fetchAderenza = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLDOMANDEFEEDBACK, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setDomande(Array.isArray(response.data.dati) ? response.data.dati : []);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };

    const fetchRisposte = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.GETALLRISPOSTEFEEDBACK, id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setRisposte(Array.isArray(response.data.dati) ? response.data.dati : []);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };

    const handleSliderChange = async (idDomandaFeedback, valutazione) => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        await web.post(ENDPOINT.SALVAFEEDBACK, { idPaziente: id, idDomandaFeedback, valutazione })
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    fetchRisposte();
                }
            }).catch((error) => {
                console.error("Error saving feedback", error);
            });
    };

    // Initialize the value for each question
    const getSliderValue = (idDomandaFeedback) => {
        // Find the current response for the question, if it exists
        const response = risposte.find(r => r.idDomandaFeedback === idDomandaFeedback);
        return response ? response.valutazione : 1; // Default to 1 if no response found
    };

    return (
        <>
            <div>
                <h2 className="text-center">Gradimento</h2>
            </div>
            {Array.isArray(domande) && domande.map((item, index) => {
                return (  
                    <Row key={index}>
                        <Col><h6>{item.testo}</h6></Col>
                        <Col>
                            <Form.Range
                                min={1} // Minimum value
                                max={5} 
                                defaultValue={getSliderValue(item.id)}// Maximum value // Set the initial value based on risposte state
                                onChange={(e) => handleSliderChange(item.id, parseInt(e.target.value))}
                                className="custom-slider"
                            />
                        </Col>
                    </Row>
                );
            })}
        </>
    );
}

export default Feedback;
