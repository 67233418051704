import axios from 'axios';
import { API } from './Constants';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    changeOrigin: true
  },
});
const httpMultiPart = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
// Variabile per gestire la richiesta di rinnovo del token
let isRefreshing = false;
let failedQueue = [];

// Interceptor per la richiesta
http.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['Content-Type'] = `application/json`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpMultiPart.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['Content-Type'] = `multipart/form-data`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Interceptor per la risposta
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Se il rinnovo del token è già in corso, metti questa richiesta in coda
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((newAccessToken) => {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);  // Riprova lci soa richiesta originale
        });
      }

      // Rinnovo del token
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refreshToken');
      let dto = localStorage.getItem("role");
      dto = JSON.parse(dto);

      if (refreshToken) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/Utente/RefreshToken`, {
            username: dto.username,
            refreshToken: refreshToken,
          });

          const newAccessToken = response.data.dati.accessToken;
          const newRefreshToken = response.data.dati.refreshToken;

          // Salva i nuovi token
          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);

          // Rilascia tutte le richieste in coda con il nuovo access token
          failedQueue.forEach(({ resolve }) => resolve(newAccessToken));
          failedQueue = [];

          // Riprova la richiesta originale con il nuovo token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed', refreshError);
          failedQueue.forEach(({ reject }) => reject(refreshError));
          failedQueue = [];
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }
    }
    return Promise.reject(error);
  }
);


export const api = {
  get: (url, id) => http.get(url + id),
  getAll: (url) => http.get(url),
  post: (url, o) => http.post(url, o),
  put: (url, o, id) => http.put(`${url}${id || ''}`, o),
  delete: (url, id) => http.delete(url + id),
  deleteData: (url, data) => http.delete(url, { data }),
  getWithParam: (url, o) => http.get(url, o),
  upload: (url, data) => httpMultiPart.post(url, data),
  getBlob: (url) => http.get(url, { responseType: 'blob' }),
};

export const user = {
  get: (url, id) => http.get(API.USER + url + id),
  getAll: (url) => http.get(API.USER + url),
  getMe: () => http.get(API.USER),
  post: (url, o) => http.post(`${API.USER + url}`, o),
  put: (url, o, id) => http.put(url + API.USER + url + id, o),
  delete: (url, id) => http.delete(API.USER + url + id),
  getWithParam: (url, o) => http.get(API.USER + url, o),
};
export const patient = {
  get: (url, id) => http.get(`${API.PATIENT + url}` + id),
  getAll: (url) => http.get(API.PATIENT + url),
  getMe: () => http.get(API.PATIENT),
  post: (url, o) => http.post(`${API.PATIENT + url}`, o),
  postDiagnosticTest: (url, idPaziente, idAnalisi, dateReferto, tipoReferto) => http.post(`${API.PATIENT + url}`, idPaziente, idAnalisi, dateReferto, tipoReferto),
  postMedicalExamination: (url, idPaziente, idVisita, tipoVisita, dataVisita, informazioniVisita) => http.post(`${API.PATIENT + url}`, idPaziente, idVisita, tipoVisita, dataVisita, informazioniVisita),
  postNutritionalDiaries: (url, idPaziente, idNutritionalDiary, data) => http.post(`${API.PATIENT + url}`, idPaziente, idNutritionalDiary, data),
  put: (url, o, id) => http.put(API.PATIENT + url + id, o),
  delete: (url, id) => http.delete(API.PATIENT + url + id),
  getWithParam: (url, o) => http.get(API.PATIENT + url, o),
};
export const operator = {
  get: (url, id) => http.get(`${API.OPERATOR + url}` + id),
  getAll: (url) => http.get(API.OPERATOR + url),
  getMe: () => http.get(API.OPERATOR),
  post: (url, o) => http.post(`${API.OPERATOR + url}`, o),
  put: (url, o, id) => http.put(API.PATIENT + url + id, o),
  delete: (url, id) => http.delete(API.PATIENT + url + id),
  getWithParam: (url, o) => http.get(API.PATIENT + url, o),
};
export const web = {
  get: (url, id) => http.get(`${API.WEB + url}` + id),
  getAll: (url) => http.get(API.WEB + url),
  getMe: () => http.get(API.WEB),
  post: (url, o) => http.post(`${API.WEB + url}`, o),
  put: (url, o, id) => http.put(API.WEB + url + id, o),
  delete: (url, id) => http.delete(API.WEB + url + id),
  getWithParam: (url, o) => http.get(API.WEB + url, o),
};
export const medico = {
  get: (url, id) => http.get(API.MEDICO + url + id),
  getAll: (url) => http.get(API.MEDICO + url),
  getMe: () => http.get(API.MEDICO),
  post: (url, o) => http.post(`${API.MEDICO + url}`, o),
  put: (url, o, id) => http.put(API.MEDICO + url + id, o),
  delete: (url, id) => http.delete(API.MEDICO + url + id),
  getWithParam: (url, o) => http.get(API.MEDICO + url, o),
};
