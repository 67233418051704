
import {  Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
function RowCustom(props) {
    let listValue = [];
     props.colums.forEach((item) => {

            //CREA ELEMENTO PER LINK
            if (props.link === item) {
                let element = {};
                element.id = props.item[String(props.reference)];
                element.value = props.item[item];
                element.controller = props.controller;
                element.isLink = true;
                element.isUpdate = false;
                element.isDelete = false;
                listValue.push({ element });
            }
            //RICHIAMA FUNZIONE PADRE (UPDATE)
            else if (props.elementUpdate === item) {
                let element = {};
                element.id = props.item[String(props.reference)];
                element.value = props.item[item];
                element.controller = props.controller;
                element.isLink = false;
                element.isUpdate = true;
                element.isDelete = false;
                listValue.push({ element });
            }
            //RICHIAMA FUNZIONE PADRE (DELETE)
            else if (props.elementDelete === item) {
                let element = {};
                element.id = props.item[String(props.reference)];
                element.value = props.item[item];
                element.controller = props.controller;
                element.isLink = false;
                element.isUpdate = false;
                element.isDelete = true;
                listValue.push({ element });
            }
            //MOSTRA IL VALORE
            else {
                let object = item.split('.');
                let element = {};
                element.id = props.item[String(props.reference)];
                element.controller = props.controller;
                if (object.length === 1) {
                    element.value = props.item[item];
                }
                if (object.length === 2) {
                    element.value = props.item[object[0]][object[1]];
                }
                if (object.length === 3) {
                    element.value = props.item[object[0]][object[1]][object[2]];
                }

                element.isLink = false;
                element.isUpdate = false;
                element.isDelete = false;
                listValue.push({ element });
            }
        })
    
    return (<tr >
        {listValue.map((el, index) =>

            el.element.isLink ?
                <td key={`${el.element.id}-link-${index}`}  id={el.element.id} ><Link to={`/${el.element.controller}/${el.element.id}`}>{el.element.value != null ? String(el.element.value) : ''}</Link></td>
                : 
                el.element.isUpdate ?
                    <td  key={`${el.element.id}-link-${index}`}  id={el.element.id}><Button onClick={() => props.update(el.element.id)} > {props.customIconUpdate? props.customIconUpdate :<FontAwesomeIcon icon={faPencil} className='c-icon-w'/>}</Button></td>
                    :
                    el.element.isDelete ?
                        <td  key={`${el.element.id}-link-${index}`}  id={el.element.id}><Button onClick={() => props.delete(el.element.id)} > <FontAwesomeIcon icon={faTrash} className=''/></Button></td>
                        :
                        <td key={`${el.element.id}-link-${index}`}  id={el.element.id}><span>{el.element.value != null ? String(el.element.value) : ''}</span></td>


        )}
    </tr>);
}
export default RowCustom;