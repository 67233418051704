import React, { useState, useRef ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import SimpleReactValidator from 'simple-react-validator'
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import { NotificationContainer, NotificationManager } from 'react-notifications';
function RegistrazioneMedico() {
    const validator = useRef(new SimpleReactValidator())
    const [formData, setFormData] = useState({
        IdMedico: 0,
        nome: '',
        cognome: '',
        email: '',
        codiceMedico:'',
        numeroTelefono: '',
        idCentroMedico:0,
        centroMedico: '',
    });

    const [forceUpdate, setForceUpdate] = useState(false);
    const [centrimedici, setCentrimedici] = useState([]);
    useEffect(() => {
        const fetchCentriMedici = async () => {
            await web.getAll(ENDPOINT.ELENCOCENTRIMEDICIATTIVI)
                .then((response) => {
                    if (response.status === 200) {
                      setCentrimedici(response.data.dati);
                    }
                }).catch((error) => {

                });
        }

        fetchCentriMedici();
        fetchProfile();
    }, []);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOMEDICO, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleInputChangeCentro = (e) => {
        const { name, value } = e.target;
        const propertyName = 'idCentroMedico';
        setFormData({ ...formData, [name]: parseInt(value),  [propertyName]: parseInt(value)});
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            web.post(ENDPOINT.SALVAMEDICO, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.MEDICO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchProfile();
                    }else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchProfile();
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };


    const validations = {
        nome: validator.current.message(
            'nome',
            formData.nome,
            'required'
        ),
        cognome: validator.current.message(
            'cognome',
            formData.cognome,
            'required'
        ),
        email: validator.current.message(
            'email',
            formData.email,
            'required|email'
        ),
        centroMedico: validator.current.message(
            'centroMedico',
            formData.centroMedico,
            'required'
        ),
    }

    return (
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2 className="text-center">{parseInt(window.location.pathname.split('/').pop()) ?
                    "Modifica Medico" :
                    "Registrazione Medico"
                }</h2>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control
                                isInvalid={validations.nome != null}
                                type="text"
                                name="nome"
                                value={formData.nome}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formCognome">
                            <Form.Label>Cognome:</Form.Label>
                            <Form.Control
                                isInvalid={validations.cognome != null}
                                type="text"
                                name="cognome"
                                value={formData.cognome}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                     disabled={parseInt(window.location.pathname.split('/').pop()) ? true:false}
                        isInvalid={validations.email != null}
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNumeroTelefono">
                    <Form.Label>Telefono :</Form.Label>
                    <div className="position-relative">
                        <Form.Control
                            isInvalid={validations.numeroTelefono != null}
                            type={ 'input' }
                            name="numeroTelefono"
                            value={formData.numeroTelefono}
                            onChange={handleInputChange}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formNumeroCodiceMedico">
                    <Form.Label>Codice Medico :</Form.Label>
                    <div className="position-relative">
                        <Form.Control
                            isInvalid={validations.codiceMedico != null}
                            type={ 'input' }
                            name="codiceMedico"
                            value={formData.codiceMedico}
                            onChange={handleInputChange}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCentroMedico">
                    <Form.Label>Centro Medico:</Form.Label>
                    <Form.Select
                        className={validations.centroMedico != null ?'validation-custom':''}
                        name="centroMedico"
                        value={formData.centroMedico}
                        onChange={handleInputChangeCentro}
                    >
                         {parseInt(window.location.pathname.split('/').pop()) ?
                                    <option value={formData.nomeCentro} >{formData.nomeCentro}</option> :
                                    <option value="">Seleziona Centro Medico</option>
                                }
                                {centrimedici.map((item) =>
                                    <option key={item.id} value={item.id}>{item.nomeCentro}</option>
                                )}
                    </Form.Select>
                     {validations.centroMedico != null ?<p className='custom-invalid-feedback'>Seleziona un centro medico</p>:<></>}
                </Form.Group>
                
                <Button type="submit" variant="primary" className="w-100">{parseInt(window.location.pathname.split('/').pop()) ?
                    "Modifica" :
                    "Registra"
                }</Button>
            </Form>
            < NotificationContainer />
        </div>
    );
}

export default RegistrazioneMedico;