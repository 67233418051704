import React from 'react';
import TreeView, { flattenTree } from "react-accessible-treeview";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrashCan, faPlus, faArrowTurnDown ,faRepeat } from '@fortawesome/free-solid-svg-icons';

function CustomTreeView(props) {
  const list = { name: props.titolo, children: [] };
  const returnLabel = (tipodomanda) => {
    return tipodomanda === APERTA ? "Aperta" :
      tipodomanda === NUMERICA ? "Numerica" :
        tipodomanda === RISPSINGOLA ? "Risposta singola" :
          tipodomanda === DATA ? "Data" :
            tipodomanda === VF ? "Vero Falso" : ""
  }

  const APERTA = 1;
  const NUMERICA = 2;
  const RISPSINGOLA = 3;
  const DATA = 4;
  const VF = 5;
  // Funzione per costruire l'albero basato su idPadre
  function buildTree(items) {
    const tree = {};

    // Creiamo un oggetto per ogni domanda usando il suo id
    items.forEach(item => {
      tree[item.id] = {
        id: item.id,
        name: item.domanda,           // Aggiungi il nome della domanda
        parent: item.idDomandaCollegata, // Aggiungi l'ID della domanda collegata (idPadre)
        children: []                  // Inizializza i figli come array vuoto
      };
    });

    // Colleghiamo i figli a ciascun nodo
    items.forEach(item => {
      if (item.idDomandaCollegata !== null) {
        // Se c'è un idDomandaCollegata, aggiungi il nodo come figlio
        tree[item.idDomandaCollegata].children.push(tree[item.id]);
      } else {
        // Se non c'è un idDomandaCollegata, aggiungi come nodo principale
        list.children.push(tree[item.id]);
      }
    });
  }

  // Costruire l'albero dalla lista di domande
  buildTree(props.items);

  // Appiattire l'albero in una struttura che TreeView può visualizzare
  const data = flattenTree(list);

  return (
    <TreeView
      data={data}
      className="basic"
      aria-label="basic example tree"
      nodeRenderer={({
        element,
        isBranch,
        isExpanded,
        getNodeProps,
        level,
        handleExpand,
      }) => (
        <div {...getNodeProps({ onClick: handleExpand })} style={{ paddingLeft: 20 * (level - 1) }}>
          {element.parent ? (
            <Row className='f-family'>
              Domanda {element.id} - {returnLabel(props.items.find(x => x.id === element.id).tipodomanda)} collegata alla domanda {element.parent} se la risposta è
              {props.items.find(x => x.id === element.id).typeConfronto === APERTA ? ' maggiore di ' :
                props.items.find(x => x.id === element.id).typeConfronto === NUMERICA ? ' minore di ' : ' uguale a '}
              {props.items.find(x => x.id === element.id).valoreConfronto}
            </Row>
          ) : (
            <Row className='f-family'>
              Domanda {element.id} - {returnLabel(props.items.find(x => x.id === element.id).tipodomanda)}
            </Row>
          )}
          <Row >
            <Form.Group as={Col} xs={1}>
              {isBranch && <ArrowIcon isOpen={isExpanded} />}
            </Form.Group>
            <Form.Group as={Col} xs={9}>
              <Form.Text disabled={true} className={'b-input-disable-custom'} name="domanda" >{element.name} </Form.Text>
            </Form.Group>
            <Form.Group as={Col} xs={1}>
              <Button className='b-border-custom' variant="secondary" id="button-addon1" onClick={() => props.deleteQuestion(element.id)}>
                <FontAwesomeIcon icon={faTrashCan} className='c-icon-gr' />
              </Button>
            </Form.Group>
            {props.items.find(x => x.id === element.id).tipodomanda !== APERTA ? (
              <Form.Group as={Col} xs={1}>
                <Button className='b-border-custom' variant="secondary" id="button-addon1" onClick={() => props.addDomandaCollegata(element.id)}>
                  <FontAwesomeIcon icon={faRepeat} className='c-icon-g' />
                </Button>
              </Form.Group>
            ) : null}
            
          </Row>
          {!element.parent ?
           <Row >
           <Form.Group as={Col} xs={12} sm={5}>
             <p className="linea-trattini"></p>
           </Form.Group>
         
           <Form.Group as={Col} xs={12} sm={2} className="d-flex justify-content-center align-items-center">
             <Button
               className="b-border-custom"
               variant="secondary"
               onClick={() => props.aggiungiDomandaIdPadre(element.id)}
             >
               <FontAwesomeIcon icon={faPlus} className="c-icon-g" />
             </Button>
           </Form.Group>
         
           <Form.Group as={Col} xs={12} sm={5}>
             <p className="linea-trattini"></p>
           </Form.Group>
         </Row>

            : <Row >
            <Form.Group as={Col} >
             <p className="linea-trattini-co"></p>
           </Form.Group>
          </Row>
          }
        </div>
      )}
    />
  );
}

const ArrowIcon = ({ isOpen, className }) => {
  if (isOpen) {
    return <FontAwesomeIcon icon={faArrowTurnDown} className='c-icon-g' />;
  } else {
    return <FontAwesomeIcon icon={faArrowRight} className='c-icon-g' />;
  }
};
export default CustomTreeView;
