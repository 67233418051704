import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
function TestEsame(props) {
    const [esami, setEsami] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(10);
    const [showmodalDelete, setShowmodalDelete] = useState(false);
    const [modalNewEsame, setModalNewEsame] = useState(false);
    const [idDelete, setidDelete] = useState(false);
    const [formData, setFormData] = useState({
        test: '',
        idEsame: props.dettaglioEsame.id,
        valore: '',
        isOltreSoglia: false
    });
    useEffect(() => {
        fetchTestEsami();
    }, []);
    const fetchTestEsami = async () => {
        await web.post(ENDPOINT.GETTESTESAME, props.dettaglioEsame.id)
            .then((response) => {
                if (response.status === 200 && response.data.dati != null) {
                    setEsami(response.data.dati);
                }
            }).catch((error) => {
                console.error("Error fetching data", error);
            });
    };
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = (pageNumber) => {
        setCurrentPage(pageNumber + 1)
    }
    const prevPage = (pageNumber) => {
        setCurrentPage(pageNumber - 1)
    }
    const showModalDelete = (id) => {
        setidDelete(id)
        setShowmodalDelete(true)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleInputChangeSwitch = (e) => {
        var isOltreSoglia = 'isOltreSoglia';
        setFormData({ ...formData, [isOltreSoglia]: formData[isOltreSoglia] ? false : true });
    };
    const handleClose = () => setModalNewEsame(false);
    const handleCloseDelete = () => setShowmodalDelete(false);
    const handleSubmit = (e) => {

        web.post(ENDPOINT.SALVATESTESAME, formData)
            .then(async (response) => {
                if (response.status === 200 && response.data.statoEsito === 0) {
                    NotificationManager.success(message.RISULTATO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                    fetchTestEsami();
                } else {
                    NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                }
            }).catch((error) => {
                NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

            })
            .finally(() => {
                fetchTestEsami();
            });
    };
    const eliminatest = (e) => {
        web.delete(ENDPOINT.ELIMINATESTESAME, e)
            .then(async (response) => {
                if (response.status === 200 && response.data.statoEsito === 0) {
                    fetchTestEsami();
                    setShowmodalDelete(false)
                } else {
                    NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                }
            }).catch((error) => {
                NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

            })
            .finally(() => {
                fetchTestEsami();
            });
    };
    return (
        <div>
            <h2 className="text-center">Tipo di esame: {props.dettaglioEsame.tipoEsame} del {props.dettaglioEsame.dataEsame} </h2>
            <Row className='pb-5'>
                <Col className="d-flex justify-content-start">
                    <Button variant="primary" onClick={props.mostralistaEsami}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} className='' />
                    </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" onClick={() => setModalNewEsame(true)}>
                        Aggiungi risultato analisi
                    </Button>
                </Col>
            </Row>
            <div class="table-wrapper">
                <table class="table custom">
                    <thead>
                        <tr>
                            <th scope="col">Risultato numero</th>
                            <th scope="col">Test</th>
                            <th scope="col">Valore</th>
                            <th scope="col">Oltre la soglia</th>
                            <th scope="col">Elimina</th>
                        </tr>
                    </thead>
                    <tbody className="rounded-row">
                        {esami?.map((pa) => <RowCustom colums={["id", "test", "valore", "isOltreSoglia", "action"]} elementUpdate={"dettaglio"} update={(el) => props.dettaglioEsame(pa)} reference={'idPaziente'} elementDelete={'action'} delete={(el) => showModalDelete(pa.id)} item={pa} />)}
                    </tbody>
                </table>
                <Pagination
                    elementPerPage={elementsPerPage}
                    totalElements={esami?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                    prevPage={prevPage}
                    nextPage={nextPage}
                />
            </div>
            <Modal show={modalNewEsame} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Nuovo risultato '}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="formNome">
                                <Form.Label>Risultato :</Form.Label>
                                <Form.Control
                                    //isInvalid={validations.nome != null}
                                    type="text"
                                    name="test"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formNome">
                                <Form.Label>Valore  :</Form.Label>
                                <Form.Control
                                    //isInvalid={validations.nome != null}
                                    type="text"
                                    name="valore"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                        </Col>
                        <Col>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Valore oltre la soglia"
                                onClick={handleInputChangeSwitch}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        {'Salva'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showmodalDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Elimina Test'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{'Vuoi eliminare questo test'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => eliminatest(idDelete)}>
                        {'Elimina'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <NotificationContainer></NotificationContainer>
        </div>
    );
}

export default TestEsame;
