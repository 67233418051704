import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import moment from 'moment';
import { isAdmin, isOperatore } from '../../utils';
import { IsMedico } from '../../utils';
function RegistrazionePaziente() {
    const validator = useRef(new SimpleReactValidator())
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        email: '',
        numeroTelefono: '',
        codiceMedico: null,
        dataInizioAssunzione: null,
        idComune: null,
        idProvincia: null,
        idPaziente: 0,
        canAccessEsami: false
    });
    const [comuni, setComuni] = useState([]);
    const [filteredComuni, setFilteredComuni] = useState([]);
    const [provincie, setProvincie] = useState([]);
    const [isDisabledComune, setIsDisabledComune] = useState(true);
    const [readOnly] = useState(IsMedico() ? true : false);
    const [minDate] = useState(new Date());
    useEffect(() => {
        const fetchProvincie = async () => {
            await web.post(ENDPOINT.ELENCOPROVINCIE)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setProvincie(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
        const fetchComuni = async () => {
            await web.post(ENDPOINT.ELENCOCOMUNE)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setComuni(response.data.dati);
                    }
                }).catch((error) => {

                });
        }

        fetchProvincie();
        fetchComuni()
        fetchProfile();
    }, []);
    const [forceUpdate, setForceUpdate] = useState(false);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOPAZIENTE, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                        if (response.data.dati.provinciaResidenza) {
                            const provincia = provincie.find(p => p.nomeProvincia === response.data.dati.provinciaResidenza);
    
                            if (provincia) {
                                setFormData(prevData => ({
                                    ...prevData,
                                    idProvincia: provincia.id.toString()
                                }));
                            }
                        }
                        if (response.data.dati.comuneResidenza) {
                            const comue = comuni.find(p => p.nomeComune === response.data.dati.comuneResidenza);
    
                            if (comue) {
                                setFormData(prevData => ({
                                    ...prevData,
                                    idComune: comue.id
                                }));
                            }
                        }
                    }
                }).catch((error) => {

                });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleInputChangeComune = (e) => {
        const comuneId = e.target.value ? parseInt(e.target.value) : null;
        setFormData({ ...formData, idComune: comuneId });
    };
    const handleInputChangeProvincia = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFilteredComuni(comuni.filter(x => x.idProvincia === parseInt(value)));
        setIsDisabledComune(false);
    };
    const handleSubmit = (e) => {

        if (validator.current.allValid()) {
            if (formData.dataInizioAssunzione) {
                formData.dataInizioAssunzione = moment(formData.dataInizioAssunzione, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD");
            }
            web.post(ENDPOINT.SALVAPAZIENTE, formData)
                .then(async (response) => {
                    if (response.status === 200 && response.data.statoEsito === 0) {
                        NotificationManager.success(message.PAZIENTE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchProfile();
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchProfile();
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };
    const validations = {
        nome: validator.current.message(
            'nome',
            formData.nome,
            'required'
        ),
        cognome: validator.current.message(
            'cognome',
            formData.cognome,
            'required'
        ),
        email: validator.current.message(
            'email',
            formData.email,
            'required|email'
        ),
        codiceMedico: validator.current.message(
            'codiceMedico',
            formData.codiceMedico,
            'required|number'
        ),
        numeroTelefono: validator.current.message(
            'numeroTelefono',
            formData.numeroTelefono,
            'required|number'
        ),
        idProvincia: validator.current.message(
            'idProvincia',
            formData.idProvincia,
            'required'
        ),
        idComune: validator.current.message(
            'idComune',
            formData.idComune,
            'required'
        ),

        dataInizioAssunzione: validator.current.message(
            'dataInizioAssunzione',
            formData.dataInizioAssunzione,
            'required'
        ),
    }
    const handleDateChange = (date) => {
        const { name, value } = date.target;
        setFormData({ ...formData, [name]: value });
    }
    const handleCheckboxChange = () => {
        setFormData((prevState) => ({
            ...prevState,
            canAccessEsami: !prevState.canAccessEsami,  // Inverte il valore di 'canAccessEsami'
        }));
    };
    return (
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            {parseInt(window.location.pathname.split('/').pop()) ?
                <h2 className="text-center">Modifica Paziente</h2>
                : <h2 className="text-center">Registrazione Paziente</h2>
            }
            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="formNome">
                        <Form.Label>Nome:</Form.Label>
                        <Form.Control
                            disabled={readOnly}
                            isInvalid={validations.nome != null}
                            type="text"
                            name="nome"
                            value={formData.nome ? formData.nome : ''}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formCognome">
                        <Form.Label>Cognome:</Form.Label>
                        <Form.Control
                            disabled={readOnly}
                            isInvalid={validations.cognome != null}
                            type="text"
                            name="cognome"
                            value={formData.cognome ? formData.cognome : ''}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            disabled={parseInt(window.location.pathname.split('/').pop()) ? true : false}
                            isInvalid={validations.email != null}
                            type="email"
                            name="email"
                            value={formData.email ? formData.email : ''}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Telefono :</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                disabled={readOnly}
                                isInvalid={validations.numeroTelefono != null}
                                type={'text'}
                                name="numeroTelefono"
                                value={formData.numeroTelefono ? formData.numeroTelefono : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Codice Medico :</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                disabled={readOnly}
                                isInvalid={validations.codiceMedico != null}
                                type={'number'}
                                name="codiceMedico"
                                value={formData.codiceMedico ? formData.codiceMedico : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formCentroMedico">
                        <Form.Label>Provincia:</Form.Label>
                        <Form.Select
                            disabled={readOnly}
                            className={validations.idProvincia != null ? 'validation-custom' : ''}
                            name="idProvincia"
                            value={formData.idProvincia}
                            onChange={handleInputChangeProvincia}

                        >
                            {parseInt(window.location.pathname.split('/').pop()) ?
                                <option value={formData.provinciaResidenza} >{formData.provinciaResidenza}</option> :
                                <option value="">Seleziona provincia</option>
                            }
                            {provincie.map((item) =>
                                <option value={item.id} id={item.id}>{item.nomeProvincia}</option>
                            )}
                        </Form.Select>
                        {validations.idProvincia != null ? <p className='custom-invalid-feedback'>Seleziona una provincia</p> : <></>}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formCentroMedico">
                        <Form.Label>Comune:</Form.Label>
                        <Form.Select
                            className={validations.idComune != null ? 'validation-custom' : ''}
                            name="idComune"
                            value={formData.idComune || ''}
                            onChange={handleInputChangeComune}
                            disabled={isDisabledComune || readOnly}
                        >
                            {parseInt(window.location.pathname.split('/').pop()) ?
                                <option value={formData.comuneResidenza} >{formData.comuneResidenza}</option> :
                                <option value="">Seleziona Comune</option>
                            }
                            {filteredComuni.map((item) =>
                                <option key={item.id} value={item.id}>{item.nomeComune}</option>
                            )}
                        </Form.Select>
                        {validations.idComune != null ? <p className='custom-invalid-feedback'>Seleziona un comune</p> : <></>}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="col-12">
                        <span id="label-inizio">Data inizio assunzione: </span>
                        <div className="input-group mb-3">
                            <input
                                disabled={readOnly}
                                type="date"
                                min={minDate.toISOString().split('T')[0]}
                                className="form-control form-control-sm"
                                id="dataInizioAssunzione"
                                name="dataInizioAssunzione"
                                aria-describedby="label-inizio"
                                onChange={handleDateChange}
                                value={
                                    formData.dataInizioAssunzione
                                        ? moment(formData.dataInizioAssunzione, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD")
                                        : moment().format("YYYY-MM-DD")
                                }
                            />
                        </div>
                    </div>
                    {validations.dataInizioAssunzione != null ? <p className='custom-invalid-feedback'>Seleziona una data </p> : <></>}
                </Col>
                <Col className="pt-5  ">
                    <Form.Check

                        type="switch"
                        id="custom-switch"
                        checked={formData.canAccessEsami}
                        onChange={handleCheckboxChange}
                        label={'Visualizza Analisi'}
                    />
                </Col>
            </Row>
            {isAdmin() || isOperatore() ?
                <Button onClick={() => handleSubmit()} variant="primary" className="w-100">{parseInt(window.location.pathname.split('/').pop()) ?
                    "Modifica" :
                    "Registra"
                }</Button> : <></>
            }
            <NotificationContainer />
        </div>
    );
}

export default RegistrazionePaziente;