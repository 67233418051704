import React, { useState, useRef, useEffect, useReducer } from 'react';
import { ENDPOINT, PATH, entitiesLabels, message } from '../../helpers/Constants';
import { user } from '../../helpers/api';
import { Form, Button, Modal, Row } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
export default function Login() {
    const validator = useRef(new SimpleReactValidator())
    const validatorOTP = useRef(new SimpleReactValidator())
    const validatorFirstAccess = useRef(new SimpleReactValidator())
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [nuovapassword, setNuovapassword] = useState('');
    const [confermapassword, setConfermaPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [isSuccessSendOtp, setIsSuccessSendOtp] = useState(false);
    const [isFirstAcces, setIsFirstAcces] = useState(false);
    const [otp, setOtp] = useState(0);
    const [phone, sePhone] = useState(0);
    useEffect(() => {
        document.body.className = "splash custom-login";
    }, []);
    function returnSplash() {
        window.location.href = "/";
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        setOtp(value);
    };

    function postLogin() {
        if (validator.current.allValid()) {
            user.post("LoginOTP", { username, password })
                .then((response) => {
                    if (response.data.statoEsito === 0) {
                        localStorage.setItem('accessToken', response.data.dati.accessToken);
                        localStorage.setItem('refreshToken', response.data.dati.refreshToken);
                        localStorage.setItem('role', JSON.stringify(response.data.dati));
                        localStorage.setItem('nominativo', JSON.stringify(response.data.dati.nominativo));
                        NotificationManager.success("Otp inviato correttamente", entitiesLabels.SUCCESS, 3000);
                        sePhone(JSON.parse(localStorage.getItem("role")).phone)
                        setIsSuccessSendOtp(true)
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorLogin, entitiesLabels.ERROR, 3000);
                });
        } else {
            if (!username || !password) {
                validator.current.showMessages();
                NotificationManager.warning(' ', '', 3000);
                forceUpdate();
            } else {
                validator.current.showMessages();
                NotificationManager.warning('message.ErrorMailNotValid', 'entitiesLabels.WARNING', 3000);
                forceUpdate();
            }
        }
    };
    function verifyOtp() {
        if (validatorOTP.current.allValid()) {
            user.post("VerifyLoginOTP/", { idDispositivo: '', phone: phone, otp: otp })
                .then((response) => {
                    if (response.data.statoEsito === 0 && response.data.descrizioneEsito !== "Codice OTP non valido") {
                        localStorage.setItem('accessToken', response.data.dati.accessToken);
                        localStorage.setItem('refreshToken', response.data.dati.refreshToken);
                        localStorage.setItem('role', JSON.stringify(response.data.dati));
                        if (response.data.dati.isPasswordProvv === true) {
                            setIsSuccessSendOtp(false)
                            setIsFirstAcces(true)
                        } else {
                            window.location.href = PATH.LISTAPAZIENTI;
                        }
                    } else {
                        this.validator.showMessages();
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                        this.forceUpdate();
                    }
                }).catch((error) => {
                    NotificationManager.warning(message.ErrorRequire, entitiesLabels.WARNING, 3000);
                });
        }
        else {
            this.validator.showMessages();
            NotificationManager.warning(message.ErrorRequire, entitiesLabels.WARNING, 3000);
            this.forceUpdate();
        }
    }
    function changFirstPassword() {
        if (validatorFirstAccess.current.allValid()) {
            user.post(ENDPOINT.CAMBIOPASSWORD, {username:username, oldPassword: password, newPassword: nuovapassword, confirmPassword: confermapassword })
                .then((response) => {
                    if (response.data.statoEsito === 0) {
                       window.location.href = PATH.LISTAPAZIENTI;
                    } else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                        this.forceUpdate();
                    }
                }).catch((error) => {
                    NotificationManager.warning(message.ErrorRequire, entitiesLabels.WARNING, 3000);
                });
        }
        else {
            this.validator.showMessages();
            NotificationManager.warning(message.ErrorRequire, entitiesLabels.WARNING, 3000);
            this.forceUpdate();
        }
    }
    const validations = {
        username: validator.current.message(
            'Email',
            username,
            'required|email'
        ),
        password: validator.current.message(
            'Password',
            password,
            'required'
        ),
    };
    const validationsOTP = {
        otp: validatorOTP.current.message(
            'opt',
            otp,
            'required'
        ),
    };
    const validationsFirstAcces = {
        nuovapassword: validatorFirstAccess.current.message(
            'nuovapassword',
            nuovapassword,
            'required'
        ),
        confermapassword: validatorFirstAccess.current.message(
            'confermapassword',
            confermapassword,
            'required'
        ),
    };
    return (
        <>
            <form action="" className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 mb-3">
                        <Form.Control isInvalid={validations.username != null} onChange={event => setUsername(event.target.value)} type="email" name="username" placeholder="E-mail" onKeyDown={event => { if (event.key === 'Enter') { this.postLogin() } }} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 mb-3 mb-md-3">
                        <div className="position-relative">
                            <Form.Control isInvalid={validations.password != null} onChange={event => setPassword(event.target.value)} id="password" name="password" type={showPassword ? 'text' : 'password'} placeholder="Password" onKeyDown={event => { if (event.key === 'Enter') { this.postLogin() } }} />
                            <span
                                className="position-absolute"
                                style={{
                                    right: '10px',
                                    top: '8px',
                                    cursor: 'pointer',
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 mb-2">
                        <p className="text-center">
                            <a href={PATH.RECOVERY} className="link-recovery small">Hai dimenticato la password?</a>
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mb-3 d-flex justify-content-center justify-content">
                        <Button className='btn btn-secondary btn-arrow' onClick={() => returnSplash()} >
                            Indietro
                        </Button>
                    </div>
                    <div className="col-12 col-md-6 mb-3 d-flex justify-content-center justify-content">
                        <Button className='btn btn-primary btn-arrow' onClick={() => postLogin()} >
                            Login
                        </Button>
                    </div>
                </div>
            </form>

            <div className="modal fade" id="welcome" tabindex="-1" aria-labelledby="welcome" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni facere, facilis, ullam vero voluptatem labore.</p>
                        </div>
                        <div className="modal-footer"></div>
                    </div>
                </div>
            </div>
            {/* MODALE PER IL CONTROLLO OTP */}
            <Modal
                show={isSuccessSendOtp}
                onHide={() => setIsSuccessSendOtp(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{'Codice OTP inviato'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group className="col-12 mb-3" >
                            <Form.Label className="text-">Ti abbiamo inviato un <strong>codice di verifica</strong> al numero di cellulare indicato in fase di registrazione. Inseriscilo nel campo sottostante e premi Invia.</Form.Label>
                            {/* isInvalid={validationsOTP.otp != null} onChange={this.handleChangeOtp} */}
                            <Form.Control name="otp" alt="patiendDto" id="otpCode" placeholder="Inserisci il codice OTP" onChange={handleInputChange} isInvalid={validationsOTP.otp != null} onKeyDown={event => { if (event.key === 'Enter') {verifyOtp()} }} />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setIsSuccessSendOtp(false)}>
                        Chiudi
                    </Button>
                    <button class="btn btn-secondary mx-2" id="reinvia"  onClick={() => postLogin()} >Invia di nuovo</button>
                    <Button variant="primary" onClick={() => verifyOtp()}>
                        Invia
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODALE PER CAMBIO PASSWORD PRIMO ACCESSO */}
            <Modal
                show={isFirstAcces}
                onHide={() => setIsFirstAcces(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{'Modifica Password Provvisoria'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group className="col-12 mb-3" >
                            <Form.Label className="text-">Ti abbiamo inviato una <strong>Password Provvisoria</strong> all'email indicata in fase di registrazione. Inseriscila nell'apposto cambo, inserisci la tua nuova password e salva  </Form.Label>
                            {/* isInvalid={validationsOTP.otp != null} onChange={this.handleChangeOtp} */}
                        </Form.Group>
                    </Row>
                    <Row>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 mb-3 mb-md-3">
                                <div className="position-relative">
                                    <Form.Control isInvalid={validationsFirstAcces.nuovapassword != null} onChange={event => setNuovapassword(event.target.value)} id="nuovapassword" name="nuovapassword" type={showPassword ? 'text' : 'password'} placeholder="Nuova Password" onKeyDown={event => { if (event.key === 'Enter') { this.postLogin() } }} />

                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 mb-3 mb-md-3">
                                <div className="position-relative">
                                    <Form.Control isInvalid={validationsFirstAcces.confermapassword != null} onChange={event => setConfermaPassword(event.target.value)} id="confermapassword" name="confermapassword" type={showPassword ? 'text' : 'password'} placeholder="Conferma Passoword" onKeyDown={event => { if (event.key === 'Enter') { this.postLogin() } }} />

                                </div>
                            </div>
                        </div>

                    </Row>
                    <Row>
                        <span
                            className=""
                            style={{
                                display: 'flex',
                                'justify-content': 'flex-end',
                                left: '10px',
                                top: '8px',
                                cursor: 'pointer',
                            }}
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                        </span>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsFirstAcces(false)}>
                        Chiudi
                    </Button>
                    <Button variant="primary" onClick={() => changFirstPassword()}>
                        Modifica password e accedi
                    </Button>
                </Modal.Footer>
            </Modal>

            < NotificationContainer />
        </>
    )
}

