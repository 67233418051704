import { Form, Button} from 'react-bootstrap';
import { useState, useEffect} from 'react';
import Pagination from '../CustomComponent/Pagination';
import RowCustom from '../CustomComponent/CustomRow';
import Modal from 'react-bootstrap/Modal';
import { web } from '../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { isAdmin, isOperatore } from '../../utils';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { message, entitiesLabels, ENDPOINT , PATH} from '../../helpers/Constants';
function ListaQuestionari() {
  const navigate = useNavigate();
  const [elements,setQuestionari] = useState([]);
  // const [loading,] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredElements, setFilteredElements] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [showModal, setShowModale] = useState(false);
  const [dropOffModello, setDropOffModello] = useState({
    id: 0,
    isAttivo: 0,
  });
  
  useEffect(() => { 
    fetchQuestionari();
}, []);
const fetchQuestionari= async () => {
    await web.post( ENDPOINT.ELENCOQUESTIONARI  )
        .then((response) => {
            if (response.status === 200) {
              setQuestionari(response.data.dati);
            }
        }).catch((error) => {

        })
};
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  let currentElements = isFilter ? filteredElements?.slice(indexOfFirstElement, indexOfLastElement) : elements?.slice(indexOfFirstElement, indexOfLastElement);

  const paginate = pageNumber => setCurrentPage(pageNumber);
  const handleClose = () => setShowModale(false);
  const showModalActive = (idelement) => {
    var element = elements.find((x) => x.id === idelement);
    const id = 'id';
    const isAttivo = 'isAttivo';
    setDropOffModello({ ...dropOffModello, [id]: element.id, [isAttivo]: element.isAttivo  });
    setShowModale(true)
  }

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber + 1)
  }
  const prevPage = (pageNumber) => {
    setCurrentPage(pageNumber - 1)
  }
  const handleChange = (el) => {
    const inputValue = el.target.value;
    setSearch({
      search:
        inputValue
    });
    if (inputValue) {
      if (search && search?.search.length >= 1) {
        setIsFilter(true);
        let filteredData = elements.filter(x => String(x.titolo).includes(inputValue)
        );
        if (filteredData.length > 0) {
          setFilteredElements(filteredData);

        } else {
          setFilteredElements(filteredData);
        }
        setCurrentPage(1)
      } else {
        setFilteredElements(elements);
      }
    } else {
      setFilteredElements(elements);
    }
  };
  function toAddQuestionario() {
    navigate(PATH.MODELLO, { replace: true });
  }
  const dropOffQuestionario = (e) => {
    web.post(ENDPOINT.DROPOFFQUESTIONARIO, e)
        .then(async (response) => {
            if (response.status === 200 && response.data.statoEsito === 0) {
                NotificationManager.success(message.PAZIENTE + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                fetchQuestionari();
                setShowModale(false)
            }else {
                NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
            }
        }).catch((error) => {
            NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
        })
        .finally(() => {
          fetchQuestionari();
        });
};
  return (<>
    <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2 className="text-center">Lista Modelli Questionari</h2>
    </div>
    <Form.Group className="d-flex mb-3">
      <Form.Control className="me-3" onChange={handleChange} onPaste={handleChange} name="search" alt="medicoDTO" placeholder="Ricerca Modello" />
      {isOperatore() || isAdmin() || isOperatore() ? <Button variant="primary" onClick={() => toAddQuestionario()}>
        Aggiungi Modello
      </Button> : <></>}
    </Form.Group>
    <>
      <div className="table-wrapper">
        <table className="table custom">
          <thead>
            <tr>
              <th scope="col">Id Modello</th>
              <th scope="col">Titolo</th>
              <th scope="col">DropOff</th>
              <th scope="col">Attiva e disattiva</th>
            </tr>
          </thead>
          <tbody className="rounded-row">
            {
             isAdmin() || isOperatore() ? 
              currentElements?.map((pa) => <RowCustom  key={pa.id} colums={["id", "titolo", "isAttivo",  "actions"]} link={'id'} elementUpdate={"actions"} reference={'id'} controller={'dettagliomodello'}  update={(el) => showModalActive(el)} item={pa} />)
            :currentElements?.map((pa) => <RowCustom key={pa.id} c colums={["id", "titolo", "isAttivo",  ]} link={'id'} reference={'id'}   controller={'dettagliomodello'} item={pa} />)
            }
          </tbody>
        </table>
      </div>
    </>
    <Pagination
      elementPerPage={elementsPerPage}
      totalElements={isFilter ? filteredElements?.length : elements?.length}
      paginate={paginate}
      currentPage={currentPage}
      prevPage={prevPage}
      nextPage={nextPage}
    />
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{dropOffModello.isAttivo ? 'Disattiva Modello' : 'Attiva Modello'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dropOffModello.isAttivo ? 'Vuoi disattivare il modello ?' : 'Vuoi riattivare il modello'}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={()  => dropOffQuestionario(dropOffModello.id)}>
          {dropOffModello.isAttivo ? 'Disattiva' : 'Attiva'}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
}
export default ListaQuestionari; 