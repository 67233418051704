import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { web } from '../../helpers/api';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';

function RegistrazioneCentroMedico() {
    const validator = useRef(new SimpleReactValidator())
    const [formData, setFormData] = useState({
        nomeCentro: '',
        idProvincia: null,
        idRegione: null,
        isAbilitato:true,
        nomeRegione:''
    });

    const [provincie, setProvincie] = useState([]);
    const [filteredProvincia, setFilteredProvincia] = useState([]);
    const [regioni, setRegioni] = useState([]);
    const [isDisabledProvincia, setIsDisabledProvincia] = useState(true);

    useEffect(() => {
        const fetchRegioni = async () => {
            await web.post(ENDPOINT.ELENCOREGIONI)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setRegioni(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
        const fetchProvincie = async () => {
            await web.post(ENDPOINT.ELENCOPROVINCIE)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setProvincie(response.data.dati);
                    }
                }).catch((error) => {

                });
        }

        fetchProvincie();
        fetchRegioni()
        fetchProfile()
    }, []);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOCENTROMEDICO, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                    }
                }).catch((error) => {

                });
        }
    };
    const [forceUpdate, setForceUpdate] = useState(false);

    const handleInputChangeProvincia = (e) => {
        const {  name, value  } = e.target;
        setFormData({ ...formData, [name]: parseInt(value) });
    };

    const handleInputChangeRegione = (e) => {
        const { name, value } = e.target;
        const propertyName = 'idProvincia';
        setFormData({ ...formData, [name]: parseInt(value),  [propertyName]: null });
        setFilteredProvincia(provincie.filter(x => x.idRegione === parseInt(value)));
        setIsDisabledProvincia(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            web.post(ENDPOINT.SALVACENTROMEDICO, formData)
                .then(async (response) => {
                    if (response.status === 200  && response.data.statoEsito === 0) {
                        NotificationManager.success(message.CENTRO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                        fetchProfile();
                    }else {
                        NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
                    }
                }).catch((error) => {
                    NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);

                })
                .finally(() => {
                    fetchProfile();
                });
        } else {
            validator.current.showMessages();
            setForceUpdate(!forceUpdate);
        }
    };

    const validations = {
        nomeCentro: validator.current.message(
            'nomeCentro',
            formData.nomeCentro,
            'required'
        ),
        idProvincia: validator.current.message(
            'idProvincia',
            formData.idProvincia,
            'required'
        ),
        idRegione: validator.current.message(
            'idRegione',
            formData.idRegione,
            'required'
        ),
    }

    return (
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto' }}>
            {parseInt(window.location.pathname.split('/').pop()) ?
                <h2 className="text-center">Modifica Centro Medico</h2>
                : <h2 className="text-center">Registrazione Centro Medico</h2>
            }
            <Form onSubmit={handleSubmit}>
                <Row className="mb-2">
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formRegione">
                            <Form.Label>Regione:</Form.Label>
                            <Form.Select
                                className={validations.regione != null ? 'validation-custom' : ''}
                                name="idRegione"
                                value={formData.idRegione}
                                onChange={handleInputChangeRegione}
                            >
                                {parseInt(window.location.pathname.split('/').pop()) ?
                                    <option value={formData.nomeRegione} >{formData.nomeRegione}</option> :
                                    <option value="">Seleziona Regione</option>
                                }
                                {regioni.map((item) =>
                                    <option value={item.id} id={item.id}>{item.nomeRegione}</option>
                                )}
                            </Form.Select>
                            {validations.idRegione != null ? <p className='custom-invalid-feedback'>Seleziona una regione</p> : <></>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="formProvincia">
                            <Form.Label>Provincia:</Form.Label>
                            <Form.Select
                                className={validations.idProvincia != null ? 'validation-custom' : ''}
                                name="idProvincia"
                                value={formData.idProvincia}
                                onChange={handleInputChangeProvincia}
                                disabled={isDisabledProvincia}
                            >
                                 {parseInt(window.location.pathname.split('/').pop()) ?
                                    <option value={formData.nomeProvincia} >{formData.nomeProvincia}</option> :
                                    <option value="">Seleziona Provincia</option>
                                }
                                {filteredProvincia.map((item) =>
                                    <option value={item.id} id={item.id}>{item.nomeProvincia}</option>
                                )}
                            </Form.Select>
                            {validations.provincia != null ? <p className='custom-invalid-feedback'>Seleziona una provincia</p> : <></>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome Centro:</Form.Label>
                            <Form.Control
                                isInvalid={validations.nomeCentro != null}
                                type="text"
                                name="nomeCentro"
                                value={formData.nomeCentro}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" variant="primary" className="w-100">{parseInt(window.location.pathname.split('/').pop()) ?
                    "Modifica" :
                    "Registra"
                }</Button>
            </Form>
            < NotificationContainer />
        </div >
    );
}

export default RegistrazioneCentroMedico;