import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { web } from '../../helpers/api';
import { ENDPOINT } from '../../helpers/Constants';
import  Questionario  from './Questionario';
import  AssociazionePaziente  from './AssociazionePaziente';
function TabQuestionario() {
    const [formData, setFormData] = useState({});
    const [key, setKey] = useState('questionario');
    const [titolo, setTitolo] = useState('');
    useEffect(() => {
        fetchProfile();
    }, []);
    const fetchProfile = async () => {
        let id = window.location.pathname.split('/').pop();
        id = parseInt(id);
        if (id) {
            await web.post(ENDPOINT.DETTAGLIOQUESTIONARIO, id)
                .then((response) => {
                    if (response.status === 200 && response.data.dati != null) {
                        setFormData(response.data.dati);
                        setTitolo(response.data.dati[0].titolo)
                    }
                }).catch((error) => {

                });
        }
    };
    return (
        <Tabs
            id="questionario"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="nav secondary-menu mb-4"
        >
            <Tab eventKey="questionario" title={"Questionario titolo" }>
                <Questionario  dati={formData} titolo={titolo}/>
            </Tab>
            <Tab eventKey="associazione" title="Associa">
                <AssociazionePaziente></AssociazionePaziente>
            </Tab>
        </Tabs>
    );
}

export default TabQuestionario;