import React, { useState, useRef, useEffect, useReducer } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { message, entitiesLabels, ENDPOINT } from '../../helpers/Constants';
import SimpleReactValidator from 'simple-react-validator';
import { user } from '../../helpers/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
export default function Recovery() {
    const validator = useRef(new SimpleReactValidator())
    const [email, setUsername] = useState('');
    const [isSending, setIsSending] = useState(0);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    useEffect(() => {
        document.body.className = "splash custom-login";
    }, []);
    function returnLogin() {
        window.location.href = "/login";
    };
    function postRecovery() {
        if (validator.current.allValid()) {
            setIsSending(true);
            
             user.post(ENDPOINT.REQUESTNEWPASSWORD, email)
             .then(async (response) => {
               if (response.status === 200 && response.data.statoEsito === 0) {
                 NotificationManager.success(message.MEDICO + message.SuccessUpdate, entitiesLabels.SUCCESS, 3000);
                 window.location.href = "/Login";
               } else {
                 NotificationManager.error(response.data.descrizioneEsito, entitiesLabels.ERROR, 3000);
               }
             }).catch((error) => {
               NotificationManager.error(message.ErrorServer, entitiesLabels.ERROR, 3000);
               setIsSending(false);
             }).finally(() => {
                setIsSending(false);
              });
        } else {
            if (!email) {
                validator.current.showMessages();
                NotificationManager.warning(' ', '', 3000);
                forceUpdate();
            } 
        }
    };

    const validations = {
        email: validator.current.message(
            'Email',
            email,
            'required|email'
        ),
    };
    return (
        <>

            <form action="" className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 mb-3">
                        <Form.Control isInvalid={validations.email !== null} onChange={event => setUsername(event.target.value)} name="email" placeholder="Inserisci  indirizzo e-mail" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-6 mb-3 d-flex justify-content-center justify-content">
                        <Button className="btn btn-secondary btn-arrow" onClick={() => returnLogin()}>
                            Indietro
                        </Button>
                    </div>
                    <div className="col-6 col-md-6 mb-3 d-flex justify-content-center justify-content ">
                        <Button className='btn btn-primary btn-arrow' onClick={() => postRecovery()} >{isSending === true ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : ''}
                            Invia
                        </Button>
                    </div>
                </div>
                < NotificationContainer />
            </form>

        </>
    )

}

