import React, { Component } from 'react';
import  {PATH}  from '../../helpers/Constants';
import RancareIcon from '../../img/output.png';
export class Splash extends Component {
    userModelProp = () => ({
    });
    constructor(props) {
        super(props);
        this.state = {
        }
        this.reset = this.reset.bind(this);
    }
    componentDidMount() {
        this.reset();
        document.body.className = "splash";
    }
    reset = () => {
        window.localStorage.clear();
    };
    render() {
        return (
            <><img src={RancareIcon} alt="Logo" height={200} />
                <div style={{ "textAlign": "center" }}><h1 className="h1 mb-5">Making the future, <span className="text-secondary">caring for life</span></h1></div>
                <div style={{ "textAlign": "center" }}>
                    <a href={PATH.LOGIN} className="btn btn-secondary btn-arrow mx-2">Accedi</a>
                </div>
            </>
        )
    }
}

export default Splash
